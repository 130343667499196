import React, { useState } from 'react';
import data from './data'
import './Info.css';
import  InfoCard from './InfoCard';

function Info({selArea, selPOI}){

  const areaDetails = [
    "Η Αγορά της Μεσσήνης, το πολιτικό, θρησκευτικό και εμπορικό κέντρο της πόλης, καταλαμβάνει τεράστια ορθογώνια έκταση 35 στρεμμάτων. Εδώ αποκαλύφθηκαν τα ιερά που αναφέρει ο περιηγητής Παυσανίας, του Δία Σωτήρα, του Ποσειδώνα και της Αφροδίτης, της Μητέρας των Θεών και της Δήμητρας με αγάλματα των Διοσκούρων. Η ανασκαφική σκαπάνη έφερε στο φως και άλλα πολλά, οικοδομήματα, γλυπτά, επιγραφικές και αρχαιολογικές μαρτυρίες, ψηφίδες της δημόσιας ζωής στην αρχαία πόλη.",
    "Το ιερό του θεού θεραπευτή, ο οποίος στην αρχαία Μεσσήνη διέθετε έντονη πολιτική υπόσταση, εντυπωσίασε τον αρχαίο περιηγητή με το πλήθος των τεχνέργων που το κοσμούσε. Σήμερα, απογυμνωμένο από τα θαυμαστά αγάλματα, αναστηλωμένο όμως, εξακολουθεί να εκπέμπει την περασμένη του αίγλη. Το εμβληματικό ιερό περιβάλλεται από μνημεία διαφόρων εποχών, με χαρακτήρα δημόσιο και ιδιωτικό, ανάμεσα τους ένα σπάνιο λουτρό ελληνιστικών χρόνων.",
    "Τη σημασία της αγωνιστικής ζωής για την κοινωνία της Μεσσήνης μαρτυρεί η άριστα διατηρημένη, μετά τις εργασίες αναστήλωσης και διαμόρφωσης, κολοσσιαία αρχιτεκτονική ενότητα Στάδιο-Γυμνάσιο Παλαίστρα, μία από τις πλέον εντυπωσιακές της αρχαίας πόλης. Την εικόνα συμπληρώνουν σημαντικά ταφικά μνημεία και μία έπαυλη, κατοικίες ανθρώπων στη γη και στο επέκεινα."
  ]
  return <div className="info-outer">{
      ["Αγορά", "Ασκληπιείο","Στάδιο & Γυμνάσιο"].map((areaTitle,areaIdx) =>
        <InfoCard img={`area${areaIdx}`} key={`area${areaIdx}`} title={areaTitle} details= {areaDetails[areaIdx]} isVisible={selArea === areaIdx+1 && selPOI === null}/>
      )
    }
    {
      [0,1,2].map((areaIdx) =>
        data[areaIdx].map((poi,poiIdx) => (
          <InfoCard img={`${areaIdx}-${poiIdx}`} key={`poi${areaIdx}-${poiIdx}`} title={poi.title} details={poi.details} isVisible={selArea === areaIdx+1 && selPOI === poiIdx}/>
          )
        )
      )
  }</div>
}

export default Info;
