import React, { useState, useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import data from './data'
import Menu from './Menu'
import Poi from './Poi'
import Info from './Info'

let port = null;
let writer = null;
let timer = null;

async function serialConnnect(){
  if ("serial" in navigator) {
    console.log("Awesome, The serial port is supported.")
    const ports = await navigator.serial.getPorts();
    // console.log(ports);
    if(ports.length === 1)
       port = ports[0];
    else {
      port = await navigator.serial.requestPort();
    }
  }
  console.log(port);
}

function App() {
  const [selArea, setSelAreaSt] = useState(0);
  const [selPOI, setSelPOI] = useState(null);

  const setSelArea = (id) => {
    if(id === selArea)return;
    setSelAreaSt(id);
    setSelPOI(null);
    sendCommand(id);
  }

  useEffect(() => {
    if(timer)clearTimeout(timer);
    timer = window.setTimeout(() => {
      setSelArea(0);
    }, 180000);
  }, [selPOI, selArea])

  const sendCommand = async (cmd) =>{
    console.log("message start", port);
    if(!port)return;
    if(!port.writable){
      await port.open({ baudRate: 115200,  });
      const textEncoder = new TextEncoderStream();
      const writableStreamClosed = textEncoder.readable.pipeTo(port.writable);
      writer = textEncoder.writable.getWriter();
    }
    await writer.write(`${cmd}\r`);
    console.log("message sent")
    console.log(`${cmd}\r${cmd}`)
  }

  if(!port)serialConnnect();

  return (
    <>
    <div className={`app app-${selArea}`}>
      <img src= "/img/map2.jpg" className={`map map-${selArea}`} />
	    <div className="btn btn-1" onClick={() => setSelArea(1)}	/>
	    <div className="btn btn-2" onClick={() => setSelArea(2)}	/>
	    <div className="btn btn-3" onClick={() => setSelArea(3)}	/>
      {selArea > 0 && data[selArea - 1].map((d,idx) => (
        <div key={`${selArea}-${idx}`} onClick={() => setSelPOI(idx)} className={idx === selPOI ? "poi poi-sel" : "poi"} style={{position:"absolute", left:`${d.x}%`, top:`${d.y}%`}}>
        <Poi/>
        </div>
      ))}
    </div>
    <Menu {...{selArea, setSelArea, selPOI, setSelPOI}}/>
    <Info {...{selArea, selPOI}}/>
    <div className={`header-title`} onClick={() => setSelArea(0)}>ΑΡΧΑΙΑ ΜΕΣΣΗΝΗ</div>
    </>
  );
}

export default App;
