function Poi() {
  return (
<div className="poi-rotate" style={{top :"-50%", left :"-50%"}}>
  <div className="poi-inner" style={{top :"-50%", left :"-50%"}}/>
  <div className="poi-inner" style={{top : "50%", left :"-50%"}}/>
  <div className="poi-inner" style={{top :"-50%", left : "50%"}}/>
  <div className="poi-inner" style={{top : "50%", left : "50%"}}/>
</div>
)}

export default Poi;
