import React, { useState } from 'react';
import data from './data'
import './Menu.css';
import  Poi from './Poi';

function Menu({selArea, setSelArea, selPOI, setSelPOI}){
  return <div className={`menu-outer menu-outer-${selArea}`}>{
      ["ΑΓΟΡΑ", "ΑΣΚΛΗΠΙΕΙΟ","ΣΤΑΔΙΟ & ΓΥΜΝΑΣΙΟ"].map((areaTitle,areaIdx) =>
        <div className="menu-area" key={areaIdx} onClick={() => setSelArea(areaIdx + 1)}>
          <div className="menu-title" onClick={() => setSelPOI(null)}>{areaTitle}</div>
          <div className={selArea-1 ===  areaIdx ? "menu-poi-outer menu-poi-outer-sel" : "menu-poi-outer"}>
          {selArea-1 ===  areaIdx && data[areaIdx].map((d,idx) => (
            <div key={idx} onClick={() => setSelPOI(idx)} className={idx === selPOI ? "menu-poi menu-poi-sel" : "menu-poi"} >
              <div className="menu-poi-icon" ><Poi/></div> {d.title}
            </div>
          ))}
          </div>
        </div>
      )
  }</div>
}

export default Menu;
