export default [
  [{x: 37, y: 17, title: "Θέατρο", details: "Το Αρχαίο Θέατρο της Μεσσήνης είναι ένα από τα μεγάλα αρχαία θέατρα των κλασικών και ελληνιστικών χρόνων, ισάξιο με το Θέατρο της Επιδαύρου. Το Θέατρο της Μεσσήνης φιλοξενούσε θεατρικές παραστάσεις και άλλα θεάματα και ακόμη λειτουργούσε ως χώρος μαζικών συγκεντρώσεων πολιτικού χαρακτήρα."},
  {x: 32, y: 15, title: "«Εν οίκω Εκκλησία»", details: "Ο Ευκτήριος Οίκος, βρίσκεται δυτικά του Θεάτρου και είναι ένα σπάνιο παράδειγμα πολυτελούς κατοικίας της ύστερης ρωμαιοκρατίας, η οποία χρησιμοποιήθηκε ως «εὐκτήριος Οἶκος», ως μια «ἐν Οἴκωι Ἐκκλησία» του 4ου αι. μ.Χ., πριν από την καθιέρωση του αρχιτεκτονικού τύπου της Βασιλικής."},
  {x: 49, y: 19, title: "Κρήνη Αρσινόη", details: "Ανάμεσα στο Θέατρο και την Αγορά αποκαλύφθηκε η μεγάλη Κρήνη. Η Κρήνη περιλαμβάνει μακρόστενη δεξαμενή μήκους 40 μ. περίπου, η οποία βρίσκεται σε μικρή απόσταση μπροστά από αναλημματικό τοίχο και δυο δεξαμενές χαμηλότερα εκατέρωθεν της εισόδου."},
  {x: 34, y: 25, title: "Ιερό της Ίσιδας", details: "Η θέση του ιερού του Σάραπη και της Ίσιδας δίπλα στο Θέατρο είναι γνωστή από τον Παυσανία. Η έρευνα έφερε στο φως μεγάλη υπόγεια στοά σχήματος «Π» αμέσως νότια από την σκηνή του Θεάτρου και επίσης νοτιότερα ένα συγκρότημα από δώδεκα μεγάλες υπόγειες καμαροσκέπαστες αίθουσες που συνδέονται με θυραία ανοίγματα."},
  {x: 40, y: 28, title: "Βασιλική", details: "Η τρίκλιτη Βασιλική της Μεσσήνης (16x38μ.) χρονολογείται στις αρχές του 6 ου αι. μ.Χ., στα χρόνια της βασιλείας του Ιουστινιανού, και είναι χτισμένη με spolia προγενέστερων οικοδομημάτων, όπως δείχνουν η πλακόστρωση του μεσαίου κλίτους και ο τοίχος της αψίδας."},
  {x: 56, y: 22, title: "Ρωμαϊκό λουτρό", details: "Στον ερειπιώνα της διώροφης Βόρειας Στοάς της Αγοράς οικοδομήθηκε στις αρχές του 5ου αι. μ.Χ., ένα βαλανείο, δηλαδή ένα ρωμαϊκό λουτρό μικρών διαστάσεων που λειτουργούσε ως τα μέσα τουλάχιστον του 7ου αι. μ.Χ. Το λουτρό περιλάμβανε όλους τους αναγκαίους για τη λειτουργία του χώρους (prefurnium, caldarium, tepidarium, piscina, frigidarium)."},
  {x: 67, y: 24, title: "Βόρεια στοά της Αγοράς", details: "Το βόρειο όριο της Αγοράς αποτελεί μια τρίκλιτη διώροφη στοά του 3ου αι. π.Χ., στα ανατολικά της Κρήνης Αρσινόης. Η στοά, έχει μήκος 196 μ., δωρική κιονοστοιχία στην πρόσοψη και δύο σειρές κορινθιακών κιόνων στο εσωτερικό, οι οποίες ορίζουν τα κλίτη. Ο χαρακτήρας της δεν ήταν εμπορικός και λειτούργησε ως τα τέλη του 4ου αι. μ.Χ."},
  {x: 52, y: 34, title: "Ναός της Μεσσάνας", details: "Ο περίπτερος Ναός της Μεσσάνας, είναι δωρικού ρυθμού και στέγαζε το χρυσόλιθο λατρευτικό άγαλμα της Θεάς. Το ιερό αποτελούσε το κέντρο της πολιτικής ζωής της πόλης. Η Θεά Μεσσάνα ήταν η προ-δωρική βασίλισσα της χώρας, που έδωσε το όνομά της στην πόλη."},
  {x: 52, y: 27, title: "Κρεοπωλείο", details: "Πρόκειται για οικοδόμημα με δωρική στοά στην πρόσοψη, το οποίο βρίσκεται στη δυτική πλευρά της Αγοράς και ταυτίζεται με Κρεοπωλείο, σύμφωνα με επιγραφή επισκευών του 1ου αι. μ.Χ. («παρά το Κρεοπώλιον στοά»). Πίσω από τη στοά υπήρχαν στην αρχαιότητα καταστήματα που επικοινωνούσαν, μέσω θυραίων ανοιγμάτων, με περιστύλιο."},
  {x: 57, y: 28, title: "Βουλείο", details: "Πρόκειται για ορθογώνιο οικοδόμημα (20x40 μ.), που ταυτίζεται, σύμφωνα με επιγραφική μαρτυρία, με το «Βουλείον», το πρώτο βουλευτήριο της πρωτεύουσας του 4ου αι. π.Χ. Η οροφή της αίθουσας συνεδριάσεων στηριζόταν σε δεκαέξι ευμεγέθεις κτιστούς πεσσούς. Δύο είσοδοι ανοίγονται στη νότια πρόσοψη της αίθουσας, μπροστά από την οποία βρίσκεται προστώο."},
  {x: 47, y: 32, title: "Πρυτανείο", details: "Το Πρυτανείο είναι ελληνιστικό οικοδόμημα με δωρική κιονοστοιχία στην πρόσοψη, άρρηκτα δεμένο με τον Ναό της Θεάς Μεσσάνας. Το κτίσμα περιλαμβάνει δύο κύριες αίθουσες, η μία από τις οποίες, αυτή με την έκκεντρη θύρα, λειτουργούσε ως δειπνιστήριο των πρυτάνεων."}
],[
  {x: 55, y: 43, title: "Ναός του Ασκληπιού", details: "Ο Παυσανίας παρουσιάζει το Ασκληπιείο ως μουσείο έργων τέχνης, κυρίως αγαλμάτων και όχι ως συνηθισμένο τέμενος θεραπείας ασθενών. Το ιερό του ήταν ο επιφανέστερος χώρος της Μεσσήνης, κέντρο της δημόσιας ζωής της πόλης, που λειτουργούσε παράλληλα με την παρακείμενη Αγορά."},
  {x: 62, y: 44, title: "Εκκλησιαστήριο", details: "Το Εκκλησιαστήριο είναι μια μικρή θεατρική κατασκευή με κοίλο εγγεγραμμένο ορθογώνιο κέλυφος και κυκλική ορχήστρα. Στο μνημείο λάμβαναν χώρα λατρευτικές παραστάσεις και συγκεντρώσεις πολιτικού χαρακτήρα. Τον 2ο-3ο αι. μ.X., η ορχήστρα του Εκκλησιαστηρίου επιστρώθηκε με πολύχρωμες πλάκες, λευκές, ερυθρές και γκρίζες."},
  {x: 60, y: 49, title: "Βουλευτήριο", details: "Το Βουλευτήριο είναι μια ορθογώνια σχεδόν τετράγωνη αίθουσα (19x18,30 μ.) με δύο εισόδους στα δυτικά και ένα προστώο. Ανήκει στο συγκρότημα του Ασκληπιείου."},
  {x: 50, y: 39, title: "Ιερό της Ορθίας Αρτέμιδος", details: "Το 1844, σε πλάτωμα, στο μέσον περίπου της απόστασης προς την κορυφή της Iθώμης, στη θέση Σπέλουζα, ο Γάλλος αρχαιολόγος Philippe Le Bas εντόπισε τα θεμέλια ιωνικού ναΐσκου με δύο κίονες εν παραστάσι στην πρόσοψη, βωμό και περίβολο τεμένους με προσκτίσματα."},
  {x: 49, y: 48, title: "Ελληνιστικά λουτρά", details: "Ιδιαίτερο ενδιαφέρον παρουσιάζει η παρουσία ενός λουτρικού συγκροτήματος ελληνιστικών χρόνων, νότια του Ασκληπιείου, ενός από τα ελάχιστα σωζόμενα παρόμοια κτίσματα στον ελλαδικό χώρο. Έχει δύο κλιβάνους για τη θέρμανση νερού και πλακόστρωτες αίθουσες με λουτήρες που εξυπηρετούσαν τις ανάγκες των λουομένων."},
  {x: 62, y: 55, title: "Αστική έπαυλη", details: "Πρόκειται για μια πολυτελή αστική έπαυλη, ενταγμένη σε οικοδομικό τετράγωνο που περιβάλλεται από δρόμους, στα ανατολικά του Ασκληπιείου. Η έπαυλη συγκροτείται από τρεις βασικές αρχιτεκτονικές ενότητες. Η καταστροφή της αστικής έπαυλης χρονολογείται στις τελευταίες δεκαετίες του 4ου αι. μ.X."}
],[
  {x: 33, y: 70, title: "Στάδιο", details: "Στο Στάδιο, όπως και σε άλλους χώρους θέασης και ακρόασης της Μεσσήνης, τελούνταν γυμνικοί και ιππικοί αγώνες εφήβων, κατά τη διάρκεια των μεγάλων εορτών των Ιθωμαίων και των Ασκληπιείων."},
  {x: 40, y: 67, title: "Δωρικός κίονας", details: "Ο υψηλός δωρικός κίονας, ο οποίος στέκει αναστηλωμένος μπροστά από την ανατολική κιονοστοιχία του Σταδίου, φαίνεται ότι έφερε τον χάλκινο ανδριάντα του Μεσσήνιου ήρωα Αριστομένη και ταυτίζεται με τον «μαντικό» κίονα που αναφέρει ο Παυσανίας. Γύρω από τον κίονα λάμβανε χώρα ετησίως μαντικό δρώμενο."},
  {x: 33, y: 64, title: "Γυμνάσιο", details: "Tο Γυμνάσιο της Μεσσήνης φιλοξενούσε την «εφηβεία», θεσμό ο οποίος απαντάται σε πόλεις κατά τη διάρκεια της ελληνιστικής και ρωμαϊκής περιόδου."},
  {x: 26, y: 71, title: "Παλαίστρα", details: "Στο νότιο πέρας της δυτικής στοάς του Γυμνασίου βρίσκεται η Παλαίστρα. Το οικοδόμημα διέθετε λουτήρες, δωμάτιο διδασκαλίας και δωμάτια στα οποία διέμεναν οι έφηβοι κατά την τριετή εκπαίδευσή τους, από 17 έως 20 ετών."},
  {x: 28, y: 64, title: "Ταφικό μνημείο (Κ3)", details: "Το επιβλητικό ταφικό μνημείο Κ3, συνολικού ύψους 9,25 μ., κατασκευάστηκε για τον ενταφιασμό μιας μεσσηνιακής οικογένειας αριστοκρατών και λειτούργησε ως οικογενειακό «Ἡρίον» του πρώιμου 3ου αι. π.Χ. Το ταφικό αυτό μνημείο, με την πρωτότυπη αμφίκοιλη κωνική στέγη του, είναι ένα άριστα διατηρημένο μνημείο της Μεσσήνης."},
  {x: 26, y: 81, title: "Μαυσωλείο Σαιθιδών", details: "Το δωρικό ναόσχημο Μαυσωλείο της πανίσχυρης, οικονομικά και πολιτικά, μεσσηνιακής οικογένειας  των Σαιθιδών βρίσκεται στα νότια του Σταδίου και συνδέεται μαζί του αρχιτεκτονικά και λειτουργικά. Είναι κτισμένο πάνω σε ορθογώνιο πόδιο που προεξέχει από το τείχος ως προμαχώνας."},
]]
