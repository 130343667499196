import React, { useState } from 'react';
import data from './data'
import './Menu.css';
import  Poi from './Poi';

function InfoCard({title, details, img, isVisible}){
  return <div className={`info-card${isVisible ? " info-card-sel" : ""}`}>
    <div className="info-title">{title}</div>
    <img src= {`/img/${img}.jpg`} className="info-img" />
    <div className="info-details">{details}</div>
  </div>
}

export default InfoCard;
